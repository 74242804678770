import React from "react"
import { graphql, navigate } from "gatsby"
import Seo from "../components/common/seo"
import { Container, Row, Col, Button } from "react-bootstrap"
import { GatsbyImage } from "gatsby-plugin-image"
import Layout from "../components/common/layout"
import Banner from "../components/common/banner"
import { format, parseISO } from "date-fns"
import { pt } from "date-fns/locale"
import bgImage from "../images/articles/banner.png"

export const query = graphql`
  query Article($id: ID!) {
    api {
      article(id: $id) {
        id
        slug
        title
        created_at
        content
        image {
          url
          imageFile {
            childImageSharp {
              gatsbyImageData(
                layout: FULL_WIDTH
                placeholder: BLURRED
                aspectRatio: 2.5
              )
            }
          }
        }
      }
    }
  }
`

const ArticlePage = ({ data, pageContext, location }) => {
  const article = data.api.article
  const created_at = parseISO(article.created_at)
  const links = [
    { title: "Blog", path: "/blog" },
    { title: article.title, path: `/blog/${article.slug}` },
  ]
  return (
    <Layout>
      <Seo title={`${article.title}`} />
      <Banner title="Conteúdo" image={bgImage} links={links} />
      <Container className="article single-post half-padding-top padding-bottom">
        {article.image && (
          <Row>
            <Col>
              <GatsbyImage
                image={article.image.imageFile.childImageSharp.gatsbyImageData}
                alt=""
              />
            </Col>
          </Row>
        )}
        <Row className="bg-grey no-gutters pb-3">
          <Col md={12}>
            <div className="pt-3 px-4">
              <div className="d-flex align-items-center article-title">
                <div className="date-square">
                  <div>
                    <span className="day">{format(created_at, "dd")}</span>
                    <span className="month text-uppercase">
                      {format(created_at, "MMM", { locale: pt })}
                    </span>
                  </div>
                </div>
                <div>
                  <h2>{article.title}</h2>
                  <div className="separator" />
                </div>
              </div>
            </div>
          </Col>
          <Col>
            <div className="py-3 px-4">
              <div
                dangerouslySetInnerHTML={{ __html: article.content }}
                className="post-content"
              />
            </div>
            <div className="text-center">
              <Button onClick={() => navigate("/blog")}>Voltar</Button>
            </div>
          </Col>
        </Row>
      </Container>
    </Layout>
  )
}

export default ArticlePage
